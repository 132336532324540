import { render, staticRenderFns } from "./operational-hour-section.vue?vue&type=template&id=60f3af4e&scoped=true&"
import script from "./operational-hour-section.vue?vue&type=script&lang=js&"
export * from "./operational-hour-section.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "60f3af4e",
  null
  
)

export default component.exports